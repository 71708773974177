
export default {
  name: 'media',
  props: {
    block: Object,
    required: true
  },
  data: () => {
    return {
      playing: false
    }
  },
  mounted() {
    if (this.plyr) {
      this.plyr.player.on('playing', () => {
        console.log('skjul');
        this.playing = true;
      });

      this.plyr.player.on('pause ended', () => {
        console.log('vis');
        this.playing = false;
      })
    }
    // console.log(this.plyr)
  },
  computed: {
    cssVars() {
      return {
        '--opacity': this.block.overlay.opacity / 100
      }
    },
    plyr() {
      return this.$refs.plyr || null;
    },
    farge() {
      let farge = null;
      switch (this.block.overlay.farge) {
        case 'blaa':
          farge = "text-blue";
          break;
        case 'svart':
        default:
          farge = "text-black";
      }
      return farge;
    }
  }
}
